html {
  min-height: 100%; /* make sure it is at least as tall as the viewport */
}
body {
  max-width: 100%;
}
body {
  min-height: 100%; /* force the BODY element to match the height of the HTML element */
  background-color: #f0f1f4;
  font-family: "Manrope"; //set default font
  padding: 0;
  margin: 0;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #E2E5F4 #F6F6F9;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: #F6F6F9;
}
*::-webkit-scrollbar-thumb {
  background-color:#E2E5F4;
  border-radius: 4px;
  border: 3px solid #F6F6F9;
}

.row-center {
  display: flex;
  align-items: center;
}
.row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.mr12{
  margin-right: 12px;
}
.mr-2 {
  margin-right: 20px;
}
.mr-1 {
  margin-right: 10px;
}

.MuiAccordion-root{
  background-color: #F9F9FA !important;
  border-radius: 12px !important;
  box-shadow: none !important;
}

.module-table{
  div[class^='MTableToolbar-highlight'], div[class*='MTableToolbar-highlight']{
    color: #050d33 !important;
    background-color: #ffffff !important;
}
  
  .MuiCheckbox-colorSecondary.Mui-checked{
    color:#050d33 !important;
  }
}




